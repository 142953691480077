import React from 'react';
import './BaseLoginPage.css';
import logoImage from '../assets/images/logo512.png';
import {Container, Stack} from "@mui/material";
import {Image} from 'mui-image'

const BaseLoginPage = ({className, children}) => {

    return (<div className={`BaseLoginPage ${className}`}>
        <Container maxWidth="xs" sx={{pt: 10, height: "100%"}}>
            <Stack spacing={3}
                   direction="column"
                   justifyContent="space-evenly"
                   alignItems="center">
                <Image src={logoImage} height={200} width={200} style={{opacity: 0.50}}/>
                {children}
            </Stack>
        </Container>
    </div>);
}

export default BaseLoginPage;
