import React from "react";

const UserContext = React.createContext({
    session: {authenticated: false},
    setSession: () => {
    },
    update: () => {
    },
    login: () => {
    },
    logout: () => {
    },
    emitEvent: () => {
    },
    Api: {},
});

export default UserContext;