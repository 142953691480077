import React from 'react';

import './AdminLoginPage.css';

import {Box, Button, Link, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AdminContext from "../../contexts/AdminContext";
import BaseLoginPage from "../BaseLoginPage";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

const AdminLoginPage = React.memo(() => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const {login} = React.useContext(AdminContext);
    const [loginFailed, setLoginFailed] = React.useState(false);

    const onAdminLogin = React.useCallback((code) => {
        login(code)
            .then(authenticated => {
                setLoginFailed(authenticated !== true);
            })
            .catch((error) => {
                setLoginFailed(true);
            });
    }, [login]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const password = (e.target.password.value).trim();
        await onAdminLogin(password);
    }

    const handleReturn = async (e) => {
        e.preventDefault();
        navigate('/');
    };

    return (<BaseLoginPage className="AdminLoginPage">
        <Typography component="h1" variant="h5">
            {t('admin.login.title')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{width: "100%"}}>
            <Stack spacing={4} direction="column">
                <TextField
                    type="password"
                    inputProps={{maxLength: 128}}
                    error={loginFailed}
                    helperText={loginFailed ? t('admin.login.failed') : null}
                    required
                    variant="outlined"
                    fullWidth
                    id="password"
                    margin="normal"
                    label={t('admin.login.password')}
                    name="password"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained">
                    {t('admin.login.button')}
                </Button>
            </Stack>
        </Box>
        <Link onClick={handleReturn} variant="body1" underline="none">
            <ArrowBackIcon sx={{
                verticalAlign: 'middle', display: 'inline-flex'
            }}/> {t('admin.login.return')}
        </Link>
    </BaseLoginPage>);

});

export default AdminLoginPage;
