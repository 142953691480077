import React from 'react';

import UserContext from "./UserContext";
import Api from "../lib/api";
import {useSearchParams} from "react-router-dom";
import LoadingBackDrop from "../components/LoadingBackDrop";
import * as storage from "../lib/storage";

const emptySession = {
    authenticated: false,
    code: null,
    token: null,
    application_id: null,
    project: {id: null, url: ""},
    application_config: null
};

function UserContextProvider(props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const {children} = props;

    const [session, setSession] = React.useState(null);

    const clearSession = React.useCallback(() => {
        setSession(emptySession);
        storage.remove('code');
    }, []);

    const parseSession = (session) => {
        return {
            ...session,
            authenticated: session?.authenticated === true,
            code: session?.code || null,
            token: session?.token || null,
            project: session?.project || {id: null, url: ""},
            application_id: session?.application_id || null,
            application_config: session?.application_config || null,
        };
    }

    const update = React.useCallback(() => {
        Api.user.session.update()
            .then((session) => {
                const parsedSession = parseSession(session);
                setSession(parsedSession);
                storage.set('code', parsedSession.code);
            })
            .catch((error) => {
                clearSession();
            });
    }, [clearSession]);

    const login = React.useCallback(async (code) => {
        return Api.user.session.login(code)
            .then((session) => {
                const parsedSession = parseSession(session);
                setSession(parsedSession);
                storage.set('code', parsedSession.code);
                return (parsedSession.authenticated === true);
            })
            .catch((error) => {
                clearSession();
                return false;
            });
    }, [clearSession]);

    const logout = React.useCallback(() => {
        Api.user.session.logout()
            .then(() => {
                clearSession();
            })
            .catch((error) => {
                clearSession();
            });
    }, [clearSession]);

    const emitEvent = React.useCallback((type, data) => {
        const token = session?.token;
        if (token && token.length) {
            Api.event.emit(token, type, data)
                .catch((error) => {
                    console.error('[event error]', error);
                });
        }
    }, [session]);

    React.useEffect(() => {

        let code = searchParams.get("code");

        if (!code) {
            code = storage.get('code', null);
        }

        if (code) {
            code = code.trim();
            login(code)
                .then((authenticated) => {
                    searchParams.delete("code");
                    setSearchParams(searchParams);
                })
                .catch((error) => {
                    searchParams.delete("code");
                    setSearchParams(searchParams);
                });
        } else {
            update();
        }

        return () => {
            logout();
        }

    }, [searchParams, setSearchParams, update, login, logout]);

    if (session === null) {
        return <LoadingBackDrop/>;
    }

    return <UserContext.Provider
        value={{
            session: session,
            setSession: setSession,
            update: update,
            login: login,
            logout: logout,
            emitEvent: emitEvent,
            Api: Api.user
        }}>
        {children}
    </UserContext.Provider>;

}

export default UserContextProvider;
