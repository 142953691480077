import React from 'react';

import {loadApplication} from "../../apps/apps";
import {useTranslation} from "react-i18next";

import UserContext from "../../contexts/UserContext";

import AppBarPage from "../AppBarPage";
import LogoutIcon from '@mui/icons-material/Logout';

import './ApplicationPage.css';

const ApplicationPage = React.memo(() => {

    const {session, logout} = React.useContext(UserContext);

    const {t} = useTranslation();

    const [appTitle, updateAppTitle] = React.useState(t(`apps.${session.application_id}.title`, ''));

    const [stars, setStars] = React.useState({count: 0, max: 0});

    const handleLogout = React.useCallback(async () => {
        await logout();
    }, [logout]);

    const menuItems = React.useMemo(() => [
        {
            disabled: true,
            label: t('appBar.code', {code: session?.code}),
        }, {
            dividerBefore: true,
            icon: <LogoutIcon/>,
            label: t('appBar.logout'),
            onClick: () => handleLogout()
        }
    ], [session?.code, t, handleLogout]);

    const app = React.useMemo(() => {

        if (session?.authenticated === true) {

            try {

                const loadedApp = loadApplication(
                    session.application_id,
                    session.application_config, {
                        setStars: setStars,
                        updateAppTitle: updateAppTitle
                    });

                return loadedApp;

            } catch (e) {
                console.error(e);
                return null;
            }

        } else {
            return null;
        }

    }, [session]);

    return app?.content ?
        <AppBarPage className="ApplicationPage"
                    containerClassName="ApplicationContent"
                    containerStyle={app && app.containerStyle ? app.containerStyle : {}}
                    title={appTitle}
                    url={session.project?.url}
                    menuItems={menuItems}
                    starsMax={stars?.max > 0 ? stars.max : 0}
                    starsCount={stars?.count > 0 ? stars.count : 0}
        >
            {app.content}
        </AppBarPage> : undefined;

});

export default ApplicationPage;
