import React, {useCallback} from 'react';

import {GridActionsCellItem, GridRenderEditCellParams, useGridApiContext} from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import {useTranslation} from "react-i18next";

const ConfigurationDialog = (props) => {

    const {close, schema, uiSchema, value, header, updateValue, readonly, t} = props;

    const onSubmit = useCallback((data) => {
        updateValue(data.formData);
        close();
    }, [updateValue, close]);

    return (<Dialog
        fullWidth={true}
        maxWidth={false}
        open={true}
        onClose={close}
    >
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
            <Form schema={schema} uiSchema={uiSchema} readonly={readonly} validator={validator} onSubmit={onSubmit}
                  formData={value}
                  omitExtraData={true}>
                <DialogActions>
                    {readonly && <Button component="button" type="button" onClick={close}>
                        {t('dataGrid.actions.close')}
                    </Button>}
                    {!readonly && <>
                        <Button component="button" type="button" onClick={close}>
                            {t('dataGrid.actions.cancel')}
                        </Button>
                        <Button component="button" type="submit" autoFocus>
                            {t('dataGrid.actions.ok')}
                        </Button>
                    </>}
                </DialogActions>
            </Form>
        </DialogContent>
    </Dialog>);

};

const ConfigurationCellComponent = (params: GridRenderEditCellParams) => {

    const {id, field, schema, uiSchema, colDef, readonly} = params;
    const {t} = useTranslation();
    const apiRef = useGridApiContext();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(params.value);

    const updateValue = useCallback(async (newValue) => {
        if (!readonly) {
            apiRef.current.setEditCellValue({id, field, value: newValue, debounceMs: 200});
            setValue(newValue);
        }
    }, [apiRef, id, field, readonly]);

    const clearValue = useCallback(() => {
        if (!readonly) {
            apiRef.current.setEditCellValue({id, field, value: null, debounceMs: 200});
            setValue(null);
        }
    }, [apiRef, id, field, readonly]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <><Box sx={{width: "100%", display: 'flex', justifyContent: 'center'}}>
        {readonly && <>
            <GridActionsCellItem
                icon={(value === null ? <VisibilityOutlinedIcon/> : <VisibilityIcon/>)}
                label={t('dataGrid.actions.view')}
                className="textPrimary"
                color="inherit"
                onClick={handleClickOpen}/>
        </>}
        {!readonly && <>
            <GridActionsCellItem
                icon={(value === null ? <SettingsOutlinedIcon/> : <SettingsIcon/>)}
                label={t('dataGrid.actions.edit')}
                className="textPrimary"
                color="inherit"
                onClick={handleClickOpen}
            />
            {value !== null && <GridActionsCellItem
                icon={<ClearIcon/>}
                label={t('dataGrid.actions.clear')}
                className="textPrimary"
                color="inherit"
                onClick={clearValue}
            />}
        </>}
    </Box>
        {open && <ConfigurationDialog readonly={readonly}
                                      close={handleClose}
                                      header={colDef.headerName}
                                      schema={schema}
                                      uiSchema={uiSchema}
                                      value={value}
                                      updateValue={updateValue}
                                      t={t}
        />}
    </>;
};

export default ConfigurationCellComponent;