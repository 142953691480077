import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import BrowserLanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(resourcesToBackend((language, namespace, callback) => {
        import(`../locales/${language}/${namespace}.json`)
            .then(({default: resources}) => {
                callback(null, resources)
            })
            .catch((error) => {
                callback(error, null);
            })
    }))
    .use(BrowserLanguageDetector)
    .use(initReactI18next);

const init = () => {
    return i18n.init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: process.env && process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
};

export {
    i18n,
    init as i18nInit
}