import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import AdminContext from "../../contexts/AdminContext";

import ShareIcon from '@mui/icons-material/IosShare';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CrudDataGrid from "../../components/CrudDataGrid";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import QRCode from "react-qr-code";

const UserManagerTab = (props) => {

    const {t} = useTranslation();
    const [rows, setRows] = useState(null);
    const [projects, setProjects] = useState(null);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [shareCode, setShareCode] = useState(null);
    const {Api} = useContext(AdminContext);

    const userUrl = `${window.location.origin}/?code=${shareCode}`;

    useEffect(() => {

        Api.users.getAll()
            .then((data) => {
                setRows(data)
            }).catch((error) => {
            setRows([]);
        });

        Api.projects.getAll()
            .then((data) => {
                setProjects(data)
            }).catch((error) => {
            setProjects([]);
        });

    }, [Api]);

    const deleteRow = (id) => {
        return new Promise((resolve, reject) => {
            Api.users.remove(id)
                .then((result) => {
                    console.log('delete user:', result);
                    return resolve(result.deleted > 0);
                })
                .catch(reject);
        });
    }

    const createNewRow = () => {
        return new Promise((resolve, reject) => {
            Api.users.createNew()
                .then((result) => {
                    result.project_id = projects ? projects[0].id : null;
                    console.log('new user:', result);
                    return resolve(result);
                })
                .catch(reject);
        });
    };

    const updateRow = (row) => {
        console.log('update user: %o', row);
        return new Promise((resolve, reject) => {
            Api.users.update(row.id, row)
                .then((result) => {
                    console.log('updated user:', result);
                    return resolve(result);
                })
                .catch(reject);
        });
    };

    const createRow = (row) => {
        console.log('create user: %o', row);
        return new Promise((resolve, reject) => {
            Api.users.create(row)
                .then((result) => {
                    console.log('created user:', result);
                    return resolve(result);
                })
                .catch(reject);
        });
    };

    function handleCloseShareDialog() {
        setShareDialogOpen(false);
    }

    const handleShare = useCallback(async (user) => {
        setShareCode(user.code);
        setShareDialogOpen(true);
    }, []);

    const columns = [{
        field: 'created_at',
        headerName: t('admin.manager.users.fields.created'),
        description: "",
        width: 160,
        type: 'dateTime',
        editable: false,
        valueGetter: (value) => value && moment.utc(value).local().toDate(),
        valueFormatter: (value, row, column, apiRef) => value && moment(value).local().format('YYYY-MM-DD HH:mm'),
    }, {
        field: 'code',
        headerName: t('admin.manager.users.fields.code'),
        type: 'number',
        description: "",
        width: 100,
        editable: false
    }, {
        field: 'email', headerName: t('admin.manager.users.fields.email'), description: "", flex: 0.6, editable: true
    }, {
        field: 'project_id',
        headerName: t('admin.manager.users.fields.project'),
        description: "",
        flex: 0.4,
        editable: true,
        type: "singleSelect",
        valueOptions: projects ? projects.map((project) => {
            return {value: project.id, label: project.name}
        }) : [],
    }];

    const customActions = [{
        icon: <ShareIcon/>, label: t('dataGrid.actions.share'), onClick: handleShare
    }]


    const handleLinkCopyToClipboard = async () => {
        await navigator.clipboard.writeText(userUrl);
    };

    return <>
        <CrudDataGrid
            rows={rows !== null && projects !== null ? rows : null}
            setRows={setRows}
            columns={columns}
            deleteRow={deleteRow}
            createNewRow={createNewRow}
            updateRow={updateRow}
            createRow={createRow}
            customActions={customActions}
            editFieldToFocus="email"
            autoPageSize={true}
        />
        {shareDialogOpen && <Dialog
            open={shareDialogOpen}
            keepMounted
            height="100%"
            onClose={handleCloseShareDialog}>
            <DialogTitle>{t('admin.manager.users.share.title')}</DialogTitle>
            <DialogContent>
                <Box sx={{p: 2}}>
                    <Stack spacing={2}>
                        <Typography variant="h2" sx={{textAlign: "center"}}>
                            {shareCode}
                        </Typography>
                        <Paper sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <QRCode value={userUrl || ''}/>
                        </Paper>
                        <Alert sx={{
                            p: 2
                        }} severity="info" icon={false}
                               action={<Button color="inherit" size="small" onClick={handleLinkCopyToClipboard}>
                                   <ContentCopyRoundedIcon/>
                               </Button>}>
                            <Typography variant="body2">
                                <code>{userUrl}</code>
                            </Typography>
                        </Alert>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseShareDialog}>Close</Button>
            </DialogActions>
        </Dialog>}
    </>;
}

export default UserManagerTab;