import React, {useCallback, useContext} from 'react';

import './UserForgotPage.css';

import {Box, Button, Link, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import BaseLoginPage from "../BaseLoginPage";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {useNavigate} from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import {useSnackbar} from "notistack";

const UserForgotPage = () => {

    const {t} = useTranslation();

    const {Api} = useContext(UserContext);
    const navigate = useNavigate();

    const {enqueueSnackbar} = useSnackbar()

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const email = (e.target.email.value).trim();

        await Api.session.forgotCode(email)
            .then(() => {
                navigate('/');
                enqueueSnackbar(t('user.forgot.sent'), {variant: 'success'});
            })
            .catch((error) => {
                enqueueSnackbar(error?.message || error, {variant: 'error'});
            });
    }, [Api, navigate, enqueueSnackbar, t]);

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/');
    };

    return (<BaseLoginPage className="UserForgotPage">
        <Typography component="h1" variant="h5">
            {t('user.forgot.title')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{width: "100%"}}>
            <Stack spacing={4} direction="column">
                <TextField
                    type="text"
                    inputProps={{maxLength: 128}}
                    required
                    variant="outlined"
                    fullWidth
                    id="email"
                    margin="normal"
                    label={t('user.forgot.email')}
                    name="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained">
                    {t('user.forgot.button')}
                </Button>
            </Stack>
        </Box>
        <Link onClick={handleCancel} variant="body1" underline="none">
            <ArrowBackIcon sx={{
                verticalAlign: 'middle', display: 'inline-flex'
            }}/> {t('user.forgot.cancel')}
        </Link>
    </BaseLoginPage>);

}

export default UserForgotPage;
