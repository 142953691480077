import React from 'react';
import {useTranslation} from "react-i18next";
import {Box, Tab, Tabs} from "@mui/material";
import AdminContext from "../../contexts/AdminContext";

import AppBarPage from "../AppBarPage";
import UserManagerTab from "./UserManagerTab";
import ProjectManagerTab from "./ProjectManagerTab";
import LogoutIcon from "@mui/icons-material/Logout";

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (<Box role="tabpanel"
                 hidden={value !== index}
                 id={`tabpanel-${index}`}
                 aria-labelledby={`tab-${index}`}
                 width="100%"
                 height="100%"
                 {...other}
    >
        {value === index && children}
    </Box>);
};


const AdminManagerPage = () => {

    const {logout} = React.useContext(AdminContext);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const {t} = useTranslation();

    const handleLogout = React.useCallback(async () => {
        await logout();
    }, [logout]);

    const menuItems = React.useMemo(() => [{
        icon: <LogoutIcon/>,
        label: t('appBar.logout'),
        onClick: () => handleLogout()
    }], [t, handleLogout]);


    function handleTabChange(event, newValue) {
        setSelectedTab(newValue);
    }

    return (<AppBarPage className="AdminManagerPage"
                        containerClassName="AdminManagerContent"
                        title={t("admin.manager.title")}
                        menuItems={menuItems}
    >
        <Box display="flex" flexDirection="column" height="100%" width="100%">
            <Tabs value={selectedTab} onChange={handleTabChange} sx={{width: "100%"}}>
                <Tab label={t('admin.manager.users.tab')}/>
                <Tab label={t('admin.manager.projects.tab')}/>
            </Tabs>
            <Box flexGrow={1} sx={{p: 2}}>
                <TabPanel value={selectedTab} index={0}>
                    <UserManagerTab/>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <ProjectManagerTab/>
                </TabPanel>
            </Box>
        </Box>
    </AppBarPage>);

}

export default AdminManagerPage;
