import {useEffect, useState} from "react";

const get = (key, defaultValue) => {
    let value = localStorage.getItem(key);
    if (value === undefined || value === null) {
        return defaultValue;
    }
    try {
        return JSON.parse(value);
    } catch {
        return defaultValue;
    }
}

const set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

const remove = (key) => {
    localStorage.removeItem(key);
}

const useLocalStorageState = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        return get(key, defaultValue);
    });
    useEffect(() => {
        set(key, value);
    }, [key, value]);
    return [value, setValue];
};

export {
    get, set, remove, useLocalStorageState
}