import React, {useEffect, useState} from 'react';

import AdminContext from "./AdminContext";
import Api from "../lib/api";
import LoadingBackDrop from "../components/LoadingBackDrop";

const emptySession = {
    authenticated: false
};

function AdminContextProvider(props) {

    const {children} = props;

    const [session, setSession] = useState(null);

    const clearSession = () => {
        setSession(emptySession);
    };

    const parseSession = (session) => {
        return {
            authenticated: session?.authenticated === true
        };
    }

    const update = React.useCallback(() => {
        Api.admin.session.update()
            .then((session) => {
                setSession(parseSession(session));
            })
            .catch((error) => {
                clearSession();
            });
    }, []);

    const login = React.useCallback(async (password) => {
        return Api.admin.session.login(password)
            .then((session) => {
                setSession(parseSession(session));
                return (session.authenticated === true);
            })
            .catch((error) => {
                clearSession();
                return false;
            });
    }, []);

    const logout = React.useCallback(() => {
        Api.admin.session.logout()
            .then(() => {
                clearSession();
            })
            .catch((error) => {
                clearSession();
            });
    }, []);

    useEffect(() => {
        update();
    }, [update]);

    if (session === null) {
        return <LoadingBackDrop/>;
    }

    return <AdminContext.Provider
        value={{
            session: session, setSession: setSession, update: update, login: login, logout: logout, Api: Api.admin,
        }}>
        {children}
    </AdminContext.Provider>;

}

export default AdminContextProvider;
