import * as React from 'react';
import {useCallback} from 'react';
import Button from '@mui/material/Button';

import {
    DataGrid,
    GridActionsCellItem,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowModes,
    GridToolbarContainer,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";
import mem from "memoize";
import ConfigurationCellComponent from "./ConfigurationCellComponent";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";
import {Divider} from "@mui/material";

const EditToolbarGenerator = (props) => {
    const {fieldToFocus, createNewRow, t} = props;

    const EditToolbar = (props) => {
        const {setRows, setRowModesModel} = props;

        const handleAddClick = useCallback(async () => {
            await createNewRow()
                .then((newRowTemplate) => {
                    setRows((oldRows) => [{...newRowTemplate, isNew: true}, ...oldRows]);
                    setRowModesModel((oldModel) => ({
                        ...oldModel, [newRowTemplate.id]: {mode: GridRowModes.Edit, fieldToFocus: fieldToFocus},
                    }));
                }).catch((error) => {
                    console.log(error);
                });
        }, [setRows, setRowModesModel]);

        return <GridToolbarContainer>
            <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={handleAddClick}>
                {t('dataGrid.button.add')}
            </Button>
            <Divider orientation="vertical" flexItem/>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>;
    };

    return EditToolbar;

};


const CrudDataGrid = (props) => {

    const {
        rows,
        setRows,
        columns,
        deleteRow,
        editFieldToFocus,
        createNewRow,
        createRow,
        updateRow,
        sx,
        customActions,
        ...otherProps
    } = props;

    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();

    const {t} = useTranslation();

    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit, fieldToFocus: editFieldToFocus}});
    };

    const handleSaveClick = (id, isNew) => async () => {

        if (isNew === false) {
            await confirm()
                .then(() => {
                    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
                })
                .catch(processRowUpdateError);
        } else {
            await setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        }
    };

    const handleDeleteClick = (id) => () => {
        return confirm()
            .then(() => {
                deleteRow(id)
                    .then((result) => {
                        if (result === true) {
                            setRows(rows.filter((row) => row.id !== id));
                        }
                    })
                    .catch(processRowUpdateError);
            })
            .catch(() => {
                /* ... */
            });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel, [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (updatedRow) => {
        const wasNew = updatedRow.isNew === true;
        delete updatedRow.isNew;
        let upstreamFunc;
        if (createRow && wasNew === true) {
            upstreamFunc = createRow;
        } else if (updateRow && wasNew === false) {
            upstreamFunc = updateRow;
        }
        if (upstreamFunc) {
            updatedRow = await upstreamFunc(updatedRow);
        }
        await setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const processRowUpdateError = (error) => {
        if (!error || !error.length) return;
        console.error(error);
        enqueueSnackbar(error?.message || error, {variant: 'error'});
    };

    const handleRowModesModelChange = async (newRowModesModel) => {
        await setRowModesModel(newRowModesModel);
    };

    columns.forEach((colDef) => {
        if (colDef.type === 'json-schema-form') {
            colDef.renderCell = (params: GridRenderCellParams) => {
                const schema = params.colDef.jsonSchema && params.colDef.jsonSchema(params);
                const uiSchema = params.colDef.jsonUiSchema && params.colDef.jsonUiSchema(params);
                return <ConfigurationCellComponent {...params} readonly={true} schema={schema} uiSchema={uiSchema}/>
            };
            colDef.renderEditCell = (params: GridRenderEditCellParams) => {
                const schema = params.colDef.jsonSchema && params.colDef.jsonSchema(params);
                const uiSchema = params.colDef.jsonUiSchema && params.colDef.jsonUiSchema(params);
                return <ConfigurationCellComponent {...params} readonly={false} schema={schema} uiSchema={uiSchema}/>
            };
        }
    });

    const crudColumns = [...columns, {
        field: 'actions',
        type: 'actions',
        filterable: false,
        headerName: t('dataGrid.actions.header'),
        flex: 0.25,
        cellClassName: 'actions',
        getActions: ({id, row}) => {

            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [<GridActionsCellItem
                    icon={<SaveIcon/>}
                    label={t('dataGrid.actions.save')}
                    onClick={handleSaveClick(id, row.isNew === true)}
                />, <GridActionsCellItem
                    icon={<CancelIcon/>}
                    label={t('dataGrid.actions.cancel')}
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                />,];
            }

            return [
                ...(customActions || []).map((customAction) => {
                    return <GridActionsCellItem
                        icon={customAction.icon}
                        label={customAction.label}
                        onClick={async () => {
                            await customAction.onClick(row);
                        }}
                        color="inherit"
                    />;
                }),
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label={t('dataGrid.actions.edit')}
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />, <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label={t('dataGrid.actions.delete')}
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />
            ];
        },
    },];

    const dataGridLocale = (mem(() => {
        let dataGridLocale;
        try {
            dataGridLocale = require(`@mui/x-data-grid/locales/${t('dataGrid.locale')}.js`);
            dataGridLocale = dataGridLocale[t('dataGrid.locale')].components.MuiDataGrid.defaultProps.localeText;
        } catch (e) {
            console.error(e);
            dataGridLocale = undefined;
        }
        return dataGridLocale;
    }, {maxAge: 10000}))();


    return (<DataGrid
        rows={rows || []}
        columns={crudColumns}
        editMode="row"
        loading={rows === null}
        localeText={dataGridLocale}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        slots={{
            toolbar: EditToolbarGenerator({fieldToFocus: editFieldToFocus, createNewRow, t}),
        }}
        slotProps={{
            toolbar: {setRows, setRowModesModel},
        }}
        sx={{
            '& .actions': {
                display: 'flex', justifyContent: 'space-around',
            }, border: 0, ...sx
        }}
        {...otherProps}
    />);

};

export default CrudDataGrid;