import axios from "axios";

import mem from "memoize";

const maxAge = 1000;

const axiosApi = axios.create({
    baseURL: "/api/", headers: {
        "Content-Type": "application/json",
    },
});

/*
 * User API
 */

const updateUserSession = mem(() => {
    return new Promise((resolve, reject) => {
        axiosApi.get('/user/auth/session')
            .then((response) => resolve(response.data))
            .catch((error) => {
                if (error.response.status === 401) {
                    resolve({authenticated: false});
                } else {
                    console.log('getUserSession() error:', error);
                    reject(error);
                }
            });
    });
}, {maxAge: maxAge});

const userLogin = (code) => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/user/auth/login', {code: code})
            .then((response) => resolve(response.data))
            .catch((error) => {
                if (error.response.status === 401) {
                    resolve({authenticated: false});
                } else {
                    console.log('userLogin() error:', error);
                    reject(error);
                }
            });
    });
}

const userLogout = () => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/user/auth/logout')
            .then((response) => {
                resolve(true);
            })
            .catch((error) => {
                console.log('userLogout() error:', error);
                reject(error);
            });
    });
}

const userForgotCode = (email) => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/user/auth/forgot', {email: email})
            .then((response) => {
                resolve(true);
            })
            .catch((error) => {
                console.log('userForgotCode() error:', error);
                reject(error);
            });
    });
}

/*
 * Admin API
 */


const updateAdminSession = mem(() => {
    return new Promise((resolve, reject) => {
        axiosApi.get('/admin/auth/session')
            .then((response) => resolve(response.data))
            .catch((error) => {
                if (error.response.status === 401) {
                    resolve({authenticated: false});
                } else {
                    console.log('getAdminSession() error:', error);
                    reject(error);
                }
            });
    });
}, {maxAge: maxAge});

const adminLogin = (password) => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/admin/auth/login', {password: password})
            .then((response) => resolve(response.data))
            .catch((error) => {
                if (error.response.status === 401) {
                    resolve({authenticated: false});
                } else {
                    console.log('adminLogin() error:', error);
                    reject(error);
                }
            });
    });
};

const adminLogout = () => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/admin/auth/logout')
            .then((response) => {
                resolve(true);
            })
            .catch((error) => {
                console.log('adminLogout() error:', error);
                reject(error);
            });
    });
};


const getAllUsers = mem(() => {
    return new Promise((resolve, reject) => {
        axiosApi.get('/admin/users')
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('getAllUsers() error:', error);
                reject(error);
            });
    });
}, {maxAge: maxAge});

const removeUser = (id) => {
    return new Promise((resolve, reject) => {
        axiosApi.delete(`/admin/users/${id}`)
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('deleteUser(%s) error: %o', id, error);
                reject(error);
            });
    });
};

const createNewUser = () => {
    return new Promise((resolve, reject) => {
        axiosApi.get('/admin/users/new',)
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('newUser() error: %o', error);
                reject(error);
            });
    });
};

const createUser = (user) => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/admin/users', {...user})
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('createUser() error: %o', error);
                reject(error);
            });
    });
};

const updateUser = (id, user) => {
    return new Promise((resolve, reject) => {
        axiosApi.put(`/admin/users/${id}`, {...user, id: id})
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('updateUser() error: %o', error);
                reject(error);
            });
    });
};

const getAllProjects = mem(() => {
    return new Promise((resolve, reject) => {
        axiosApi.get('/admin/projects')
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('getAllProjects() error:', error);
                reject(error);
            });
    });
}, {maxAge: maxAge});

const removeProject = (id) => {
    return new Promise((resolve, reject) => {
        axiosApi.delete(`/admin/projects/${id}`)
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('deleteProject(%s) error: %o', id, error);
                reject(error);
            });
    });
};

const createNewProject = () => {
    return new Promise((resolve, reject) => {
        axiosApi.get('/admin/projects/new',)
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('newProject() error: %o', error);
                reject(error);
            });
    });
};


const createProject = (project) => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/admin/projects', {...project})
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('createProject() error: %o', error);
                reject(error);
            });
    });
};

const updateProject = (id, project) => {
    return new Promise((resolve, reject) => {
        axiosApi.put(`/admin/projects/${id}`, {...project, id: id})
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('updateProject() error: %o', error);
                reject(error);
            });
    });
};

const emitEvent = (token, type, data = undefined) => {
    return new Promise((resolve, reject) => {
        axiosApi.post('/event/emit', {token: token, event: {type: type, data: data}})
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log('emitEvent() error: %o', error);
                reject(error);
            });
    });
};

const Api = {
    event: {
        emit: emitEvent,
    },
    user: {
        session: {
            update: updateUserSession, login: userLogin, logout: userLogout, forgotCode: userForgotCode
        }
    },
    admin: {
        session: {
            update: updateAdminSession, login: adminLogin, logout: adminLogout,
        }, users: {
            getAll: getAllUsers, create: createUser, update: updateUser, remove: removeUser, createNew: createNewUser,
        }, projects: {
            getAll: getAllProjects,
            create: createProject,
            update: updateProject,
            remove: removeProject,
            createNew: createNewProject
        }
    }
};

export default Api;

