import React from 'react';

import UserContext from "../../contexts/UserContext";
import ApplicationPage from "./ApplicationPage";
import UserLoginPage from "./UserLoginPage";

const UserMainPage = React.memo(() => {
    const {session} = React.useContext(UserContext);
    return (session?.authenticated === true ? <ApplicationPage/> : <UserLoginPage/>);
});

export default UserMainPage;
