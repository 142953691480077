import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import AdminContext from "../../contexts/AdminContext";

import CrudDataGrid from "../../components/CrudDataGrid";
import {useTranslation} from "react-i18next";
import {getApplication, getApplications} from "../../apps/apps";

import moment from "moment";
import {GridRenderEditCellParams} from "@mui/x-data-grid";

const ProjectManagerTab = (props) => {

    const {t} = useTranslation();
    const [rows, setRows] = useState(null)
    const {Api} = useContext(AdminContext);

    useEffect(() => {

        Api.projects.getAll()
            .then((data) => {
                setRows(data)
            }).catch((error) => {
            setRows([]);
        });

    }, [Api]);

    const deleteRow = (id) => {
        return new Promise((resolve, reject) => {
            Api.projects.remove(id)
                .then((result) => {
                    console.log('delete project:', result);
                    return resolve(result.deleted > 0);
                })
                .catch(reject);
        });
    }

    const createNewRow = () => {
        return new Promise((resolve, reject) => {
            Api.projects.createNew()
                .then((result) => {
                    result.application_id = getApplications()[0].id;
                    console.log('new project:', result);
                    return resolve(result);
                })
                .catch(reject);
        });
    };

    const updateRow = (row) => {
        console.log('update project: %o', row);
        return new Promise((resolve, reject) => {
            Api.projects.update(row.id, row)
                .then((result) => {
                    console.log('updated project:', result);
                    return resolve(result);
                })
                .catch(reject);
        });
    };

    const createRow = (row) => {
        console.log('create project: %o', row);
        return new Promise((resolve, reject) => {
            Api.projects.create(row)
                .then((result) => {
                    console.log('created project:', result);
                    return resolve(result);
                })
                .catch(reject);
        });
    };


    const columns = [{
        field: 'created_at',
        headerName: t('admin.manager.projects.fields.created'),
        description: "",
        width: 160,
        type: 'datetime',
        editable: false,
        valueGetter: (value) => value && moment.utc(value).local().toDate(),
        valueFormatter: (value) => value && moment(value).local().format('YYYY-MM-DD HH:mm'),
    }, {
        field: 'name', headerName: t('admin.manager.projects.fields.name'), description: "", flex: 1, editable: true
    }, {
        field: 'url', headerName: t('admin.manager.projects.fields.url'), description: "", flex: 1, editable: true
    }, {
        field: 'application_id',
        headerName: t('admin.manager.projects.fields.application'),
        description: "",
        flex: 0.5,
        type: "singleSelect",
        editable: true,
        valueOptions: getApplications().map((app) => {
            return {value: app.id, label: app.name}
        }),
    }, {
        field: 'config',
        headerName: t('admin.manager.projects.fields.config'),
        description: "",
        width: 75,
        type: "json-schema-form",
        editable: true,
        jsonSchema: (params: GridRenderEditCellParams) => {
            return getApplication(params.row.application_id).configSchema;
        },
        jsonUiSchema: (params: GridRenderEditCellParams) => {
            return getApplication(params.row.application_id).configUiSchema;
        }
    }];

    return <CrudDataGrid
        rows={rows}
        setRows={setRows}
        columns={columns}
        deleteRow={deleteRow}
        createNewRow={createNewRow}
        updateRow={updateRow}
        createRow={createRow}
        editFieldToFocus="name"
        autoPageSize={true}
    />;
}

export default ProjectManagerTab;