import React from 'react';

import {SnackbarProvider} from "notistack";
import {ConfirmProvider} from "material-ui-confirm";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import {useTranslation} from "react-i18next";

import ErrorPage from "./pages/ErrorPage";
import UserMainPage from "./pages/user/UserMainPage";
import AdminMainPage from "./pages/admin/AdminMainPage";
import UserForgotPage from "./pages/user/UserForgotPage";
import UserContextProvider from "./contexts/UserContextProvider";
import AdminContextProvider from "./contexts/AdminContextProvider";

import './App.css';

const App = React.memo(() => {

    const {t} = useTranslation();

    const rootElement = React.useMemo(() => {

        const defaultConfigDialogOptions = {
            title: t('confirmDialog.title'),
            description: t('confirmDialog.description'),
            confirmationText: t('confirmDialog.confirmationText'),
            cancellationText: t('confirmDialog.cancellationText')
        };

        return (<div className="Root">
            <SnackbarProvider maxSnack={3}>
                <ConfirmProvider defaultOptions={defaultConfigDialogOptions}>
                    <Outlet/>
                </ConfirmProvider>
            </SnackbarProvider>
        </div>);

    }, [t]);

    const router = React.useMemo(() => createBrowserRouter([{
        path: "/",
        element: rootElement,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "/", element: <UserContextProvider><UserMainPage/></UserContextProvider>
            },
            {
                path: "/forgot", element: <UserContextProvider><UserForgotPage/></UserContextProvider>
            },
            {
                path: "/admin", element: <AdminContextProvider><AdminMainPage/></AdminContextProvider>
            }]
    }]), [rootElement]);

    return (<div className="App">
        <RouterProvider router={router}/>
    </div>);
});

export default App;
