import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import {CssBaseline} from "@mui/material";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import 'pwacompat';

import {i18nInit} from './lib/i18n';

import App from "./App";

i18nInit().then(() => {

    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        <>
            <CssBaseline/>
            <App/>
        </>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

});

