import React from 'react';

import _apps_json from "./apps.json";
import LoadingBackDrop from "../components/LoadingBackDrop";
import validator from '@rjsf/validator-ajv8';
import {getDefaultFormState} from '@rjsf/utils';


const registeredApplications = new Map();
_apps_json.registeredApplications.forEach(id => {

    const info = require(`./${id}/app-info.json`);
    const configSchema = require(`./${id}/app-config-schema.json`);
    const configUiSchema = require(`./${id}/app-config-ui-schema.json`);

    registeredApplications.set(id,
        {
            id: id,
            ...info,
            configSchema: configSchema,
            configUiSchema: configUiSchema,
            Component: null,
        });
});

const getApplications = () => {
    return [...registeredApplications.values()];
}

const applicationExists = (id) => {
    return registeredApplications.has(id);
}

const getApplication = (id) => {
    return registeredApplications.get(id);
}

const renderApplicationContent = (app, config = {}, props = {}) => {
    try {
        config = getDefaultFormState(validator, app.configSchema, config || {});
    } catch (e) {
        throw new Error('invalid application config:', e);
    }
    if (!app.Component) {
        console.log('loading application component:', app.id);
        app.Component = React.lazy(() => import(`./${app.id}/${app.main}`));
    }
    return <React.Suspense fallback={<LoadingBackDrop/>}>
        <app.Component {...props} config={config}/>
    </React.Suspense>;
}

const loadApplication = (id, config, props) => {
    if (!applicationExists(id)) throw new Error(`Application ${id} does not exist`);
    console.log('loading application:', id);
    const app = getApplication(id);
    const content = renderApplicationContent(app, config, props);
    return {...app, content: content};
}

export {
    getApplications, getApplication, applicationExists, loadApplication
}