import React from 'react';

import {
    AppBar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import './AppBarPage.css';
import StarBar from "../components/StarBar";

const AppBarPage = (props) => {

    const {
        title,
        url,
        className,
        containerClassName,
        containerStyle,
        menuItems,
        children,
        starsMax,
        starsCount
    } = props;

    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);

    const handleMenuOpen = (event) => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorElement(null);
    };

    const handleMenuClick = async (item) => {
        setMenuAnchorElement(null);
        if (item.onClick) {
            await item.onClick();
        }
    };

    return (<Box className={`AppBarPage ${className}`}>
        <Box display="flex" flexDirection="column" height="100%">
            <AppBar position="relative">
                <Toolbar>
                    {menuItems && menuItems.length ? <Box sx={{flexGrow: 0}}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={menuAnchorElement}
                            anchorOrigin={{
                                vertical: 'top', horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top', horizontal: 'left',
                            }}
                            open={Boolean(menuAnchorElement)}
                            onClose={handleMenuClose}
                        >
                            {menuItems.map((item, index) => {
                                return [
                                    item.dividerBefore === true ? <Divider/> : undefined,
                                    <MenuItem key={index} disabled={item.disabled === true}
                                              onClick={async () => handleMenuClick(item)}>
                                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                        {item.label &&
                                            <ListItemText sx={item.labelSx || {}}>{item.label}</ListItemText>}
                                    </MenuItem>,
                                    item.dividerAfter === true ? <Divider/> : undefined
                                ];

                            })}
                        </Menu>
                    </Box> : null}

                    <Typography variant="h6" textAlign="center" sx={{flexGrow: 8}}>
                        {title ? title : ''}
                    </Typography>
                    {starsMax > 0 &&
                        <Box sx={{flexGrow: 1}}>
                            <StarBar count={starsCount} max={starsMax}/>
                        </Box>
                    }
                    {url && url.length &&
                        <Box sx={{flexGrow: 0}}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="project-link"
                                href={url}
                                target="_blank"
                                rel="noopener"
                            >
                                <InfoIcon/>
                            </IconButton>
                        </Box>
                    }

                </Toolbar>
            </AppBar>
            <Box className={`AppBarPageContent ${containerClassName}`} flexGrow={1} width="100%"
                 sx={{...containerStyle}}>
                {children}
            </Box>
        </Box>
    </Box>);
}

export default AppBarPage;
