import React from 'react';

import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {Box, Slide} from "@mui/material";
import {yellow} from "@mui/material/colors";

import {Howl, Howler} from "howler";

import './StarBar.css';

Howler.autoUnlock = true;

const starSound = new Howl({
    src: ['audio/star.mp3'], preload: true
}).on('load', () => {
    starSound.volume(0.2);
    // console.log('star sound loaded');
}).on('loaderror', (id, error) => {
    console.error('star sound load error', error);
});

const loseSound = new Howl({
    src: ['audio/starlost.mp3'], preload: true
}).on('load', () => {
    loseSound.volume(0.2);
    //console.log('star lost sound loaded');
}).on('loaderror', (id, error) => {
    console.error('star lost sound load error', error);
});

const awardSound = new Howl({
    src: ['audio/award.mp3'], preload: true
}).on('load', () => {
    awardSound.volume(0.5);
    //console.log('award sound loaded');
}).on('loaderror', (id, error) => {
    console.error('award sound load error', error);
});

const StarBar = ({count, max, audio = true}) => {

    if (count > max) {
        count = max;
    }

    const [activeCount, setActiveCount] = React.useState(count);

    if (count !== activeCount) {
        if (max > 0 && audio === true) {
            if (count > activeCount) {
                starSound.play();
            } else if (count < activeCount) {
                loseSound.play();
            }
            if (count === max) {
                awardSound.play();
            }
        }
        setActiveCount(count);
    }

    const stars = React.useMemo(() => {

        const stars = [];
        for (let i = 0; i < max; i++) {

            const starClass = (activeCount === max) ? "spinningStars run" : "spinningStars pause";

            stars.push(
                <Box key={i} className={starClass}
                     style={{fontSizeAdjust: "+10", display: "inline-flex", position: "relative"}}>
                    <Slide direction="down" in={true} timeout={500} mountOnEnter unmountOnExit
                           style={{transitionDelay: i * 50}}>
                        <StarOutlineIcon sx={{opacity: 0.35, color: yellow["700"]}}/>
                    </Slide>
                    <Slide direction="up" in={i < activeCount} timeout={1000} mountOnEnter unmountOnExit
                           style={{position: 'absolute', transitionDelay: i * 50}}>
                        <StarIcon sx={{color: yellow["700"]}}/>
                    </Slide>
                </Box>
            );
        }
        return stars;
    }, [activeCount, max]);

    return <>
        {stars}
    </>;

};


export default StarBar;