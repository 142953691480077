import React from 'react';

import AdminContext from "../../contexts/AdminContext";
import AdminLoginPage from "./AdminLoginPage";
import AdminManagerPage from "./AdminManagerPage";

const AdminMainPage = React.memo(() => {
    const {session} = React.useContext(AdminContext);
    return (session?.authenticated === true ? <AdminManagerPage/> : <AdminLoginPage/>);
});

export default AdminMainPage;
