import React from "react";

const AdminContext = React.createContext({
    session: {authenticated: false}, setSession: () => {
    }, update: () => {
    }, login: () => {
    }, logout: () => {
    }, Api: {},
});

export default AdminContext;