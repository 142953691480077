import React from 'react';

import {Box, Button, Link, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import UserContext from "../../contexts/UserContext";
import BaseLoginPage from "../BaseLoginPage";

import {useNavigate} from "react-router-dom";

import './UserLoginPage.css';

const UserLoginPage = React.memo(() => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const {login} = React.useContext(UserContext);

    const [loginFailed, setLoginFailed] = React.useState(false);

    const onUserLogin = React.useCallback((code) => {
        login(code)
            .then(authenticated => {
                setLoginFailed(authenticated !== true);
            })
            .catch((error) => {
                setLoginFailed(true);
            });
    }, [login]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const code = (e.target.code.value).trim();
        await onUserLogin(code);
    }

    const handleForgot = async (e) => {
        e.preventDefault();
        navigate('/forgot');
    }

    return (<BaseLoginPage className="UserLoginPage">
        <Typography component="h1" variant="h5">
            {t('user.login.title')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{width: "100%"}}>
            <Stack spacing={4} direction="column">
                <TextField
                    type="text"
                    inputProps={{maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*'}}
                    error={loginFailed}
                    helperText={loginFailed ? t('user.login.failed') : null}
                    required
                    variant="outlined"
                    fullWidth
                    id="code"
                    margin="normal"
                    label={t('user.login.code')}
                    name="code"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained">
                    {t('user.login.button')}
                </Button>
            </Stack>
        </Box>
        <Link onClick={handleForgot} variant="body1" underline="none">
            {t('user.login.forgot')}
        </Link>
    </BaseLoginPage>);
});

export default UserLoginPage;
